/*####################
####### Retours ######
#####################*/
div.retour {
	font-family: ComputerModernSerif;
	font-size:18px;
	letter-spacing: -0.7px;

	div.panel-heading {
		background-color:lighten(@my-lblue, 20%);
		padding-top:8px;
		padding-bottom:8px;

		h3 {
			margin:0px;
			font-size:19px;
			span.label {
				float:right;
				background-color:@my-lblue;
				color:#333;
			}
		}
	}

	div.panel-collapse {
		span.question {
			font-weight:bold;
		}

		p {
			font-size:17px;
			padding:2px 7px 0px;
			margin:0px;

			&.empty {
				font-size:16px;
				font-style: italic;
				letter-spacing: -0.3px;
			}
		}

		ul li ul {
			padding:0px;
			li {
				list-style-type:none;
			}
		}
	}
}



/*##### Creation #####*/
div#retours-create-form-basic {
	border-bottom:1px dashed #AAA;
	padding-bottom:10px;
}

div#retours-create-form-questions {
	padding-top:4px;
}


/*####################
##### Références #####
####################*/
@references-table-first-col:3;
#references-container {
	div.jumbotron {
		margin-bottom:0px;
		border-bottom:1px dashed #666;
	}

	.table-header {
		background-color:@my-lblue;
	}

	#references-table {

		tr {
			padding:0px;

			td:first-of-type, th:first-of-type {
				padding-left:0px;
				padding-right:0px;

				div {
					margin-right:0px;
					@media (min-width: @screen-sm-min) {	width: @container-sm * @references-table-first-col / 12;	}
					@media (min-width: @screen-md-min) {	width: @container-md * @references-table-first-col / 12;	}
					@media (min-width: @screen-lg-min) {	width: @container-lg * @references-table-first-col / 12;	}
				}
			}

			td:last-of-type, th:last-of-type {
				padding-left:0px;
				padding-right:0px;

				div {
					margin:0px;
					@media (min-width: @screen-sm-min) {	width: @container-sm * (12 - @references-table-first-col) / 12 - 15;	}
					@media (min-width: @screen-md-min) {	width: @container-md * (12 - @references-table-first-col) / 12 - 15;	}
					@media (min-width: @screen-lg-min) {	width: @container-lg * (12 - @references-table-first-col) / 12 - 15;	}
				}
			}
		}
	}
}
