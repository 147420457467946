

/*
    Couplages
*/

@couplages-table-first-col:5;
@couplages-table-second-col:3;
#couplages-container {
    div.jumbotron {
        margin-bottom:0px;
        border-bottom:1px dashed #666;
    }

    .table-header {
        background-color:@my-yellow;
    }

    #couplages-table {
        tr {
            padding:0px;

            td:first-of-type, th:first-of-type {
                padding-left:0px;
                padding-right:0px;

                div {
                    margin-right:0px;
                    @media (min-width: @screen-sm-min) {    width: @container-sm * @couplages-table-first-col / 12;    }
                    @media (min-width: @screen-md-min) {    width: @container-md * @couplages-table-first-col / 12;    }
                    @media (min-width: @screen-lg-min) {    width: @container-lg * @couplages-table-first-col / 12;    }
                }
            }

            td:nth-of-type(2), th:nth-of-type(2) {
                padding-left:0px;
                padding-right:0px;

                div {
                    margin:0px;
                    @media (min-width: @screen-sm-min) {    width: @container-sm * @couplages-table-second-col / 12;    }
                    @media (min-width: @screen-md-min) {    width: @container-md * @couplages-table-second-col / 12;    }
                    @media (min-width: @screen-lg-min) {    width: @container-lg * @couplages-table-second-col / 12;    }
                }
            }

            td:last-of-type, th:last-of-type {
                padding-left:0px;
                padding-right:0px;

                div {
                    margin:0px;
                    @media (min-width: @screen-sm-min) {    width: @container-sm * (12 - @couplages-table-first-col - @couplages-table-second-col) / 12 - 15;    }
                    @media (min-width: @screen-md-min) {    width: @container-md * (12 - @couplages-table-first-col - @couplages-table-second-col) / 12 - 15;    }
                    @media (min-width: @screen-lg-min) {    width: @container-lg * (12 - @couplages-table-first-col - @couplages-table-second-col) / 12 - 15;    }
                }
            }
        }
    }
}


div#couplage-activate-form {
    h2 {
        font-size:@font-size-h3;
        margin-bottom:40px;
    }
}


section#couplage {
    @full-width();

    div.jumbotron {
        margin-bottom:0px;
        background-color:@my-yellow;
        padding-top:25px;
        padding-bottom:25px;
    }


    div.strip {
        @full-width();
        min-height:150px;
        padding-bottom:20px;

        h2 {
            font-size:@font-size-h3;
            font-family: ComputerModernSerif;
            letter-spacing: -0.7px;
            font-weight:bold;

            .btn {
                font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
            }
        }

        &#explications {
            overflow: visible;
            min-height:10px;
            padding-bottom:0px;
            background-color:rgb(245, 245, 245);
            border-top:1px solid rgb(221, 221, 221);
            border-bottom:1px solid rgb(221, 221, 221);
            font-family:"ComputerModernSerif";
            font-size:18px;
            letter-spacing:-0.7px;

            p {
                text-align:center;
                font-weight:bold;
                font-size:@font-size-h4;
                margin-top:10px;

                a, a:hover {
                    text-decoration:none;
                }
            }
        }


        &#parametres div {
            position:relative;
            div {
                &.row {
                    margin-bottom:6px;
                }

                & > strong {
                    .make-md-column(2);
                    padding-left:25px;
                }

                & > div, & > ul {
                    .make-md-column(10);
                }
            }

            h2 {
                margin-bottom:2px;
            }

            textarea#couplage-commentaire {
                width:100%;
                min-height:100px;
                padding:10px;
                background-color:#F5F5F0;
                box-shadow:0px 3px 15px 2px #F5F5F0;
                border-top:1px solid #ccc;
                border-left:none;
                border-bottom:1px dashed #ccc;

                position:relative;
                z-index:1;

                &:focus {
                    outline: 0;
                    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.42);
                }
            }

            button#couplage-commentaire-ok {
                display:block;
                padding:6px 8px;
                position:relative;
                left:0px;
                top:5px;
                z-index:0px;
                margin-bottom:-34px;

                transition:left 0.8s;
            }
        }



        &#apercu {
            ul {
                @full-block();
                display:flex;
                flex-wrap:wrap;

                li {
                    width:46px;
                    height:28px;
                    line-height:25px;
                    margin:-1px 0px 0px -1px;
                    padding:0px;
                    border:1px solid black;
                    text-align:center;
                    list-style-type:none;

                    &.state-0 { background-color:#999999;    }
                    &.state-1 { background-color:#fc7474;    }
                    &.state-2 { background-color:orange;     }
                    &.state-3 { background-color:@my-mgreen; }

                    a {
                        color:black;
                    }
                }
            }
        }



        &#developpements {
            background-color:@my-lyellow;
            overflow:visible;


           ul {
                display:flex;
                flex-direction:column;
                padding-left:0px;
                margin:10px 5px 10px;

                li.developpement-item {
                    order:3;
                    transition:height 1s, padding 1s, border 1s;
                    padding-left:0px;
                    overflow:hidden;

                    &:first-of-type {
                        border-radius:0px;
                    }

                    div.developpement-checkbox {
                        transition:margin 1s;
                        float:left;
                        width:39px;
                        height:42px;
                        border-right:1px solid #ddd;
                        margin:-11px 0px -11px 0px;
                        text-align:center;
                        line-height:45px;
                        cursor:pointer;

                        &::before {
                            content: '\f13e';
                            font: 1.6em 'FontAwesome';
                        }
                    }

                    div ~ div {
                        transition:margin 1s;
                        margin-left:50px;
                        margin-right:40px;

                        font-family:ComputerModernSerif;
                        font-size:18px;
                        letter-spacing:-0.4px;
                        line-height:22px;
                        margin-bottom:-2px;
                    }

                    button {
                        transition:margin 1s;
                        font-size:16px;
                        margin:-1px 2px -2px;

                        &:first-of-type {
                            margin-right:-10px;
                        }
                    }


                    &.locked {
                        order:1;
                        background-color:#e2f0dd;

                        div.developpement-checkbox::before {
                            content: '\f023';
                        }

                        div a {
                            color:black;
                        }

                        button {
                            margin-right:-40px;
                        }
                    }
                }

                li.separator {
                    order:2;
                    padding:0px;
                    border:none;
                    background-color:transparent;
                }

                li.locked ~ .separator {
                    padding:10px;
                }


                li.rating-add-container {
                    order:4;
                }
            }

            div#new-developpement {
                margin:0px;
                padding:0px 7px;
                text-align:right;

                & ~ h2 {
                    margin-top:10px;
                }
            }
        }




        &#lecons {
            h2 a.affix {
                right:10%;
                top:90px;
            }

            section.lecon {
                list-style-type:none;
                padding-left:5px;
                margin-bottom:15px;

                span.anchor {
                    display: block;
                    position: relative;
                    top: -66px;
                    visibility: hidden;
                }


                div.lecon-header {
                    border:1px solid @my-lgrey;
                    background-color:#F2DEDE;
                    padding-top:7px;

                    h3 {
                        font-size:@font-size-h4;
                        margin-right:110px;
                        margin-top:0px;
                        padding-left:7px;
                        a {
                            color:@my-dgrey;
                        }
                    }

                    div.switcher2 {
                        float:right;
                        margin-right:7px;
                    }
                }

                div.commentaire {
                    textarea {
                        resize: none;
                        width:100%;
                        min-height:40px;
                        padding:8px 10px 0px;
                        background-color:#fffeef;
                        box-shadow:0px 3px 15px 2px #F5F5F0;
                        border:1px solid #ccc;
                        border-top:none;
                        border-bottom:1px dashed #ccc;

                        position:relative;
                        z-index:1;

                        &:focus {
                            outline: 0;
                            box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.42);
                        }
                    }

                    button {
                        display:block;
                        padding:6px 8px;
                        position:relative;
                        left:0px;
                        top:2px;
                        z-index:0px;
                        margin-bottom:-34px;

                        transition:left 0.8s;
                    }
                }

                div.references {
                    margin:7px 5px 0px;

                    select {
                        height:20px;
                    }

					ul.chosen-choices {
						background-image:url('/assets/images/book.png');
						background-repeat:no-repeat;
						background-size:23px;
						background-position:8px 7px;
						padding-left:40px;

						li.search-choice {
							background-color:#efefef;
						}

						a.search-choice-close {
							top:6px;
						}
					}
				}


                ul.couverture {
                    display:flex;
                    flex-direction:column;
                    padding-left:0px;
                    margin:10px 5px 30px;

                    li.couverture-rating {
                        transition:height 1s, padding 1s, border 1s;
                        padding-left:0px;
                        overflow:hidden;
                        &.checked {
                            background-color:#EAEAEA;
                        }

                        &:first-of-type {
                            border-radius:0px;
                        }


                        div.couverture-checkbox {
                            transition:margin 1s;
                            float:left;
                            width:39px;
                            height:42px;
                            border-right:1px solid #ddd;
                            margin:-11px 0px -11px 0px;
                            text-align:center;
                            line-height:45px;
                            cursor:pointer;

                            &::before {
                                content: '\f096';
                                font: 1.6em 'FontAwesome';
                            }
                            &.checked::before {
                                content: '\f14a';
                            }
                        }

                        div.rating {
                            margin-left:11px;
                        }

                        div.rating ~ div {
                            transition:margin 1s;
                            margin-left:145px;
                            margin-right:40px;

                            font-family:ComputerModernSerif;
                            font-size:18px;
                            letter-spacing:-0.4px;
                            line-height:22px;
                            margin-bottom:-2px;
                        }

                        button {
                            transition:margin 1s;
                            font-size:16px;
                            margin:-1px 2px -2px;

                            &:first-of-type {
                                margin-right:-10px;
                            }

                            &.developpement-lock {
                                font-family:'FontAwesome';
                                font-size:20px;
                                margin-bottom:-5px;

                                i::before {
                                    content: '\f13e';
                                }
                            }
                        }

                        &.locked button {
                            margin-right:-40px;

                            &.developpement-lock {
                                margin-right:-10px;

                                i::before {
                                    content: '\f023';
                                }
                            }
                        }


                        &.checked.couverture-rating-5 { order:1;  }
                        &.checked.couverture-rating-4 { order:2;  }
                        &.checked.couverture-rating-3 { order:3;  }
                        &.checked.couverture-rating-2 { order:4;  }
                        &.checked.couverture-rating-1 { order:5;  }
                        &.couverture-rating-5         { order:6;  }
                        &.couverture-rating-4         { order:7;  }
                        &.couverture-rating-3         { order:8;  }
                        &.couverture-rating-2         { order:9;  }
                        &.couverture-rating-1         { order:10; }
                    }

                    li.rating-add-container {
                        order:11;
                    }
                }


                &.state-0 {
                    div.lecon-header {
                        background-color:#CCCCCC;
                    }

                    div.references {
                      height:0px;
                      overflow: hidden;
                    }

                    ul li{
                        height:0px;
                        padding:0px;
                        border-width:0px;
                        overflow:hidden;
                    }
                }

                &.state-2 {
                    div.lecon-header {
                        background-color:#ffd659;
                    }
                }


                &.state-3 {
                    div.lecon-header {
                        background-color:#DFF0D8;
                    }

                    ul {
                        li.couverture-rating {
                            height:0px;
                            padding:0px;
                            border-width:0px;
                            transition:height 1s, padding 1s, background 1s;
                            &.checked {
                                height:auto;
                                padding:10px 15px 10px 0px;
                                background-color:white;
                            }


                            div.couverture-checkbox {
                                margin-left:-40px;
                            }

                            div.rating ~ div {
                                margin-left:90px;
                            }

                            span.developpement-edit {
                                color:black;
                            }

                            button {
                                margin-right:-40px;
                            }
                        }

                        li.rating-add-container {
                            height:0px;
                            border-width:0px;
                            padding:0px;
                            overflow:hidden;
                        }
                    }
                }
            }
        }
    }
}


div#modal-export {
    form {
        h5 {
            font-size:@font-size-h4;
            margin-bottom:20px;
        }

        label {
            text-align:left;
        }
    }
}



div#modal-couplage-pre-explications {
    div.modal-body {
        font-size:18px;

        div {
            text-align:center;
            font-weight:bold;
            font-size:19px;
        }
    }
}

div#modal-couplage-explications {
    .item {
        padding:5px 5px 0px 5px;
    }

    .carousel-caption {
        position:relative;
        left:auto;
        right:auto;
        bottom:0px;
        margin:0px -5px;
        padding:0px 0px 30px;
        border-top:1px solid #999;
        text-shadow:none;
        color:black;
        background-color:#EEEEEE;

        h4 {
            margin-top:20px;
            margin-bottom:20px;
        }

        p {
            margin:0px 6%;
            text-align:justify;
        }
    }

    .carousel-control {
        width:10%;
    }
}

div#modal-developpement {
    p {
        text-align:justify;
        margin-bottom:0px;

        &.developpement-lien {
            padding:10px 15px;
            background-color:#F2F2F2;
            border-bottom:1px solid #ddd;
        }
    }

    div#modal-developpement-recasages {
        padding:0px;

        div.form-group {
            margin-bottom:0px;

            ul {
                margin-bottom:0px;
                padding-left:0px;

                li {
                    border-radius:0px;
                }
            }
        }
    }
}



/*#####################################
########### Pdf couplage ##############
#####################################*/
body#pdf-couplage {
    @full-width();
    font-family: ComputerModernSerif !important;
    letter-spacing: -0.7px;

    div.jumbotron {
        @full-width();
        margin-top:0px;
        padding:5px 40px 20px;
        background-color:white;
    }


    div.strip {
        @full-width();
        padding:5px 40px 20px;

        h2 {
            font-size:@font-size-h3;
            font-weight:bold;
        }

        &#apercu {
            ul {
                @full-block();
                display:flex;
                flex-wrap:wrap;

                li {
                    width:46px;
                    height:28px;
                    line-height:25px;
                    margin:-1px 0px 0px -1px;
                    padding:0px;
                    border:1px solid black;
                    text-align:center;
                    list-style-type:none;

                    &.state-0 { background-color:#999999;    }
                    &.state-1 { background-color:#fc7474;    }
                    &.state-2 { background-color:orange;     }
                    &.state-3 { background-color:@my-mgreen; }

                    a {
                        color:black;
                    }
                }
            }
        }


        &#developpements {
//            background-color:@my-lyellow;
            border-top:1px solid #444;
            border-bottom:1px solid #444;

            section.developpement {
                list-style-type:none;
                padding-left:5px;
                page-break-inside:avoid;

                div.developpement-header {
                    margin-top:5px;
                    border-top:1px solid @my-lgrey;
                    padding-top:7px;

                    h3 {
                        font-size:18px;
                        line-height:22px;
                        margin:5px 0px 5px;

                        color:@my-dgrey;
                        a {
                            color:inherit;
                            font-weight:bold;
                            margin-right:7px;
                        }
                    }
                }

                div.commentaire {
                  padding:3px 15px 7px;
                  font-size:18px;
                  font-style:italic;
                }

                ul.references {
                    display:flex;
                    flex-direction:column;
                    padding-left:0px;
                    margin:0px;

                    li {
                        list-style-type: none;
                        background-image:url('/assets/images/book.png');
        						    background-repeat:no-repeat;
        						    background-size:17px;
        						    background-position:14px 5px;
        						    padding-left:40px;
                        font-size:17px;
                        margin-bottom:5px;

                        a {
                            color:#444;
                        }
                    }
                }

                ul {
                    display:flex;
                    flex-direction:column;
                    padding-left:0px;

                    li.couverture-rating {
                        overflow:hidden;
                        border:none;
                        padding-left: 5px;

                        div.rating {
                            margin-left:11px;
                        }

                        div.rating ~ div {
                            margin-left:110px;

                            font-family:ComputerModernSerif;
                            font-size:18px;
                            letter-spacing:-0.4px;
                            line-height:22px;
                            margin-bottom:-2px;

                            a {
                                color:black;
                            }
                        }

                        &.checked.couverture-rating-5 { order:1;  }
                        &.checked.couverture-rating-4 { order:2;  }
                        &.checked.couverture-rating-3 { order:3;  }
                        &.checked.couverture-rating-2 { order:4;  }
                        &.checked.couverture-rating-1 { order:5;  }
                        &.couverture-rating-5         { order:6;  }
                        &.couverture-rating-4         { order:7;  }
                        &.couverture-rating-3         { order:8;  }
                        &.couverture-rating-2         { order:9;  }
                        &.couverture-rating-1         { order:10; }
                    }

                    li.rating-add-container {
                        order:11;
                    }
                }
            }
        }




        &#lecons {
            h2 a.affix {
                right:10%;
                top:90px;
            }

            section.lecon {
                list-style-type:none;
                padding-left:5px;
                page-break-inside:avoid;

                div.lecon-header {
                    margin-top:5px;
                    border-top:1px solid @my-lgrey;
                    padding-top:7px;

                    h3 {
                        font-family:ComputerModernSerif;
                        font-size:18px;
                        letter-spacing:-0.4px;
                        line-height:22px;
                        margin:5px 0px 5px;

                        color:@my-dgrey;
                        a {
                            color:inherit;
                            font-weight:bold;
                            margin-right:7px;
                        }
                    }
                }

                div.commentaire {
                  padding:3px 15px 7px;
                  font-size:18px;
                  font-style:italic;
                }

                ul.references {
                    margin:0px;
                    li {
                        list-style-type: none;
                        background-image:url('/assets/images/book.png');
					    background-repeat:no-repeat;
					    background-size:17px;
					    background-position:14px 5px;
					    padding-left:40px;
                        font-size:17px;
                        margin-bottom:5px;

                        a {
                            color:#444;
                        }
                    }
                }


                ul {
                    display:flex;
                    flex-direction:column;
                    padding-left:0px;

                    li.couverture-rating {
                        overflow:hidden;
                        border:none;
                        padding-left: 5px;

                        div.rating {
                            margin-left:11px;
                        }

                        div.rating ~ div {
                            margin-left:110px;

                            font-family:ComputerModernSerif;
                            font-size:18px;
                            letter-spacing:-0.4px;
                            line-height:22px;
                            margin-bottom:-2px;

                            a {
                                color:black;
                            }
                        }

                        &.checked.couverture-rating-5 { order:1;  }
                        &.checked.couverture-rating-4 { order:2;  }
                        &.checked.couverture-rating-3 { order:3;  }
                        &.checked.couverture-rating-2 { order:4;  }
                        &.checked.couverture-rating-1 { order:5;  }
                        &.couverture-rating-5         { order:6;  }
                        &.couverture-rating-4         { order:7;  }
                        &.couverture-rating-3         { order:8;  }
                        &.couverture-rating-2         { order:9;  }
                        &.couverture-rating-1         { order:10; }
                    }

                    li.rating-add-container {
                        order:11;
                    }
                }


                &.state-0 {
                    div.lecon-header {
                        background-color:#CCCCCC;
                    }

                    ul li{
                        height:0px;
                        padding:0px;
                        border-width:0px;
                        overflow:hidden;
                    }
                }
            }
        }
    }
}
