/*##################################
### Single user display ###
##################################*/

section#user {
	@full-width();

	div.jumbotron {
		margin-bottom:0px;
		background-color:@my-yellow;
		padding-top:20px;
		padding-bottom:20px;
	}


	div.strip {
		@full-width();
		min-height:150px;
		padding-bottom:20px;

		h3 {
			font-family: ComputerModernSerif;
			letter-spacing: -0.7px;
			font-weight:bold;
		}


		&#informations div {
            div {
            	&.row {
	            	margin-bottom:6px;
            	}

             	& > strong {
                    .make-md-column(2);
                    padding-left:25px;
                }

                & > div, & > ul {
                    .make-md-column(10);
                }

                &.form-group {
                	margin-bottom:6px;

                	label {
                		text-align:left;
	                    padding-left:25px;
                	}
                }
            }
		}


		&#developpements {
			background-color:@my-lyellow;
		}
	}
}