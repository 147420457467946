/*###########################
########### Lecons ##########
###########################*/


/*######################
###### Leçons list #####
######################*/
@lecons-table-first-col:1;
#lecons-container {
	overflow:auto;

	div.jumbotron {
		margin-bottom:0px;
		border-bottom:1px dashed #666;
	}

	.table-header {
		background-color:@my-lblue;
	}

	#lecons-table {
		tr {
			padding:0px;

			td:nth-of-type(2), th:nth-of-type(2) {
				display:none;
			}


			td:first-of-type, th:first-of-type {
				padding-left:0px;
				padding-right:0px;

				& > div {
					margin-right:0px;
					@media (min-width: @screen-sm-min) {	width: @container-sm * @lecons-table-first-col / 12;	}
					@media (min-width: @screen-md-min) {	width: @container-md * @lecons-table-first-col / 12;	}
					@media (min-width: @screen-lg-min) {	width: @container-lg * @lecons-table-first-col / 12;	}
				}
			}

			td:last-of-type, th:last-of-type {
				padding-left:0px;
				padding-right:0px;

				& > div {
					margin:0px;
					@media (min-width: @screen-sm-min) {	width: @container-sm * (12 - @lecons-table-first-col) / 12 - 15;	}
					@media (min-width: @screen-md-min) {	width: @container-md * (12 - @lecons-table-first-col) / 12 - 15;	}
					@media (min-width: @screen-lg-min) {	width: @container-lg * (12 - @lecons-table-first-col) / 12 - 15;	}
				}
			}
		}
	}
}



/*###############################
###### Single lecon display #####
###############################*/

section#lecon {
	@full-width();
	font-family: ComputerModernSerif;
	font-size:18px;
	text-align: justify;
	letter-spacing: -0.7px;

	div.jumbotron {
		margin-bottom:0px;
		background-color:@my-lblue;

		h1 {
			font-size:@font-size-h2;
		}

		h2 {
			font-size:@font-size-h3;
		}
	}


	div.strip {
		@full-width();
		min-height:200px;
		padding-bottom:30px;

		h2 {
			font-weight:bold;
			font-size:@font-size-h3;
		}

		&#couplage {
			font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
			font-size:14px;
			letter-spacing:0px;

			overflow: visible;
			min-height:10px;
			padding-bottom:0px;
			background-color:rgb(245, 245, 245);
			border-top:1px solid rgb(221, 221, 221);
			border-bottom:1px solid rgb(221, 221, 221);

			div.lecon {
	            div.lecon-header {
	                border:1px solid @my-lgrey;
	                border-top:none;
	                background-color:#F2DEDE;
	                padding-top:7px;

	                h3 {
	                    font-size:@font-size-h4;
	                    margin-right:110px;
	                    margin-top:0px;
	                    padding-left:7px;
	                    a {
	                        color:@my-dgrey;
	                    }
	                }

	                div.switcher2 {
	                    float:right;
	                    margin-right:7px;
	                }
	            }


				div#commentaire {
	                textarea {
	                    resize: none;
	                    width:100%;
	                    min-height:40px;
	                    padding:8px 10px 0px;
	                    background-color:#fffeef;
	                    border:1px solid #ccc;
	                    border-top:none;
	                    border-bottom:1px dashed #ccc;

	                    position:relative;
	                    z-index:1;

	                    &:focus {
	                        outline: 0;
	                        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.42);
	                    }
	                }

		            button {
		                display:block;
		                padding:6px 8px;
		                position:relative;
		                left:0px;
		                top:2px;
		                z-index:0px;
		                margin-bottom:-34px;

		                transition:left 0.8s;
		            }
		        }

				div#references {
					margin:7px 5px 0px;

					select {
                        height:20px;
                    }

					ul.chosen-choices {
						background-image:url('/assets/images/book.png');
						background-repeat:no-repeat;
						background-size:23px;
						background-position:8px 7px;
						padding-left:40px;

						li.search-choice {
							background-color:#efefef;
						}

						a.search-choice-close {
							top:6px;
						}
					}
				}

	            ul#couplage-developpements {
	                display:flex;
	                flex-direction:column;
	                padding-left:0px;
	                margin:15px 5px 10px;

	                li.couverture-rating {
	                    transition:height 1s, padding 1s, border 1s;
	                    padding-left:0px;
	                    overflow:hidden;
	                    &.checked {
	                        background-color:#EAEAEA;
	                    }

	                    &:first-of-type {
	                    	border-radius:0px;
	                    }

	                    div.couverture-checkbox {
	                        transition:margin 1s;
	                        float:left;
	                        width:39px;
	                        height:42px;
	                        border-right:1px solid #ddd;
	                        margin:-11px 0px -11px 0px;
	                        text-align:center;
	                        line-height:45px;
	                        cursor:pointer;

	                        &::before {
	                            content: '\f096';
	                            font: 1.6em 'FontAwesome';
	                        }
	                        &.checked::before {
	                            content: '\f14a';
	                        }
	                    }

	                    div.rating {
	                        margin-left:11px;
	                    }

	                    div.rating ~ div {
                            transition:margin 1s;
                            margin-left:145px;
                            margin-right:40px;

                            font-family:ComputerModernSerif;
							font-size:18px;
							letter-spacing:-0.4px;
							line-height:22px;
							margin-bottom:-2px;
	                    }

	                    button {
	                        transition:margin 1s;
	                        font-size:16px;
                            margin:-1px 2px -2px;

                            &:first-of-type {
                            	margin-right:-10px;
                            }

							&.developpement-lock {
                                font-family:'FontAwesome';
                                font-size:20px;
                                margin-bottom:-5px;

                                i::before {
                                    content: '\f13e';
                                }
                            }
	                    }

						&.locked button {
							margin-right:-40px;

							&.developpement-lock {
								margin-right:-10px;

								i::before {
									content: '\f023';
								}
							}
						}


	                    &.checked.couverture-rating-5 { order:1;  }
	                    &.checked.couverture-rating-4 { order:2;  }
	                    &.checked.couverture-rating-3 { order:3;  }
	                    &.checked.couverture-rating-2 { order:4;  }
	                    &.checked.couverture-rating-1 { order:5;  }
	                    &.couverture-rating-5         { order:7;  }
	                    &.couverture-rating-4         { order:8;  }
	                    &.couverture-rating-3         { order:9;  }
	                    &.couverture-rating-2         { order:10;  }
	                    &.couverture-rating-1         { order:11; }
	                }

	                li.separator {
	                	order:6;

	                	border:none;
	                	background-color:transparent;
	                	padding:6px;
	                }

	                li.rating-add-container {
	                    order:12;
	                }
	            }

				div#new-developpement {
					margin-bottom:10px;
					padding:0px 7px;
					text-align:right;
				}


				&.state-0 {
                    div.lecon-header {
                        background-color:#CCCCCC;
                    }

                    ul li{
                        height:0px;
                        padding:0px;
                        border-width:0px;
                        overflow:hidden;
                    }
                }

                &.state-2 {
                    div.lecon-header {
                        background-color:#ffd659;
                    }
                }


                &.state-3 {
                    div.lecon-header {
                        background-color:#DFF0D8;
                    }

                    ul {
                        li.couverture-rating {
                            height:0px;
                            padding:0px;
                            border-width:0px;
                            transition:height 1s, padding 1s, background 1s;
                            &.checked {
                                height:auto;
                                padding:10px 15px 10px 0px;
                                background-color:transparent;
                            }


                            div.couverture-checkbox {
                                margin-left:-40px;
                            }

                            div.rating ~ div {
                                margin-left:90px;
                            }

                            span.developpement-edit {
                                color:black;
                            }

                            button {
                                margin-right:-40px;
                            }
                        }

                        li.rating-add-container {
                            height:0px;
                            border-width:0px;
                            padding:0px;
                            overflow:hidden;
                        }
                    }
                }
            }
		}


		&#rapport {
			background-color:white;

			h3 {
				font-weight:bold;
				cursor:pointer;

			    span.plus {
			    	display:inline-block;
			    	font-weight:bold;
			    	font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
			        transform: rotate(45deg);
			        transform-origin:5px 15px;
			    }

			    &.collapsed span.plus {
			        transform: rotate(0deg);
			    }
			}
		}

		&#developpements {
			background-color:lighten(@my-lblue, 20%);

			ul {
				padding-left:20px;

				li {
					list-style-type: none;

					a {
						@full-block();
						padding:2px 0px;
						color:black;
						font-size:18px;

						span.display-rating {
							font-size:15px;
							letter-spacing:0px;
							margin-right:5px;

							span {
								width:16px;
							}
						}
					}
				}
			}
		}


		&#votes {
			overflow: visible;
			min-height:10px;
			padding-bottom:0px;
			background-color:rgb(245, 245, 245);
			border-top:1px solid rgb(221, 221, 221);
			border-bottom:1px solid rgb(221, 221, 221);

			p {
				text-align:center;
				font-weight:bold;
				font-size:@font-size-h4;
				margin-top:10px;


				a, a:hover {
					text-decoration:none;
					span {
						.caret;
						transform:rotate(180deg);
					}
					&.collapsed span {
						transform:rotate(0deg);
					}
				}
			}

			div#edit-votes {
				padding-bottom:20px;

				ul#list-votes {
					margin:0px;
					padding-left:20px;

					& > li.list-group-item {
						background:transparent;

						& > span, & > a {
							font-size:14px;
							margin-top:3px;
						}
					}


					.chosen-container {
					    width:100%!important;
					    font-size:18px;

					    .chosen-search input[type="text"] {
						    width:100% !important;
						}

						.chosen-drop {
	 					   width: 100%!important;
						}

						.chosen-single.chosen-default {
							height:40px;
						}
					}
				}

				input.btn {
					letter-spacing:0px;
					font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
				}
			}
		}


		&#versions {
			background-color:white;

			& > div > h2 {
				margin-bottom:20px;
			}

			h2 a.btn {
				letter-spacing:0px;
				font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
			}

			h3 {
				font-size:@font-size-h4;
			}

			.version h3 {
				font-weight:bold;
				font-size:16px;
			}
		}


		&#retours {
			background-color:lighten(@my-lblue, 20%);

			& > div > h2 {
				margin-bottom:20px;
			}

			h2 a.btn {
				letter-spacing:0px;
				font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
			}

			h3 {
				font-size:@font-size-h4;
			}

			.retour {

				div.panel-heading {
					color:#333;
			        background-color:#F5F5F5;
			        border-color:#DDD;

					h3 {
						margin:0px;
						font-size:19px;
						span.label {
							float:right;
							background-color:@my-lblue;
							color:#333;
						}
					}
				}

				div.panel-collapse {
					span.question {
						font-weight:bold;
					}

					p {
						font-size:17px;
						padding:2px 7px 0px;

						&.empty {
							font-style: italic;
						}
					}

					ul li ul {
						padding:0px;
						li {
							list-style-type:none;
						}
					}
				}
			}
		}

	}
}




/* Single lecon display*/
nav#menu_lecons ul li.sep
{
	margin:0px;
	background-color:transparent;
	color:white;
}

nav#menu_lecons ul li.sep div
{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 0px 6px 7px;
	border-color: transparent transparent transparent white;
	margin-top:6px;
}


nav#menu_lecons ul li#num_lecon
{
	height:22px;
	padding-right:10px;

	font-size:18px;
	line-height:24px;

	background-color:#B5D87E;
	color:black;
	border:1px solid white;
}


nav#menu_lecons ul li#num_lecon span
{
	display:inline-block;
	margin-right:7px;
	padding:1px 0px 0px;
	height:22px;
	width:40px;

	text-align:center;
	border-radius:7px;
	background-color:white;
	color:#83B13A;
}

nav#menu_lecons ul li#num_lecon h3
{
	display:inline;
	margin:0px;

	font-size:17px;
	font-weight:bold;
	color:#151515;
}
