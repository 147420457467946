.switcher
{
    float:left;
    position:relative;
    margin-right:15px;
    width: 50px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}

.switcher input.switcher-checkbox
{
    display: none;
}

.switcher label.switcher-label
{
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #444444;
    border-radius: 20px;
}

.switcher label.switcher-label span.switcher-inner
{
    display:block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.switcher label.switcher-label span.switcher-inner span.switcher-on,
.switcher label.switcher-label span.switcher-inner span.switcher-off
{
    display: block;
    width: 50%;
    height: 20px;
    padding: 0;

    float: left;
    line-height: 20px;
    color: white;
    font-size: 14px;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}

.switcher label.switcher-label span.switcher-inner span.switcher-on
{
    padding-left:5px;
    background-color:#DFF0D8;
    color:#3C763D;
}

.switcher label.switcher-label span.switcher-inner span.switcher-off
{
    padding-right:7px;
    padding-top:1px;
    text-align: right;
    background-color:#F2DEDE;
    color:#A94442;
}


.switcher label.switcher-label span.switcher-switch
{
    display: block;
    width: 18px;
    height:18px;
    margin: 2px;
    background: #FFFFFF;

    position: absolute;
    top: 0;
    bottom: 0;
    right: 27px;

    border: 2px solid #999999;
    border-radius: 10px;
    transition: all 0.3s ease-in 0s;
}
.switcher input.switcher-checkbox:checked + label.switcher-label span.switcher-inner
{
    margin-left: 0;
}

.switcher input.switcher-checkbox:checked + label.switcher-label span.switcher-switch
{
    right: 0px;
}



/*#################################
### New version with more state ###
#################################*/
.switcher2 {
  position:relative;
  display:flex;
  flex-direction:row;
  border:1px solid #444444;
  border-radius:20px;
  cursor:pointer;
  overflow:hidden;
  width:100px;
  height:25px;
  transition: background 0.3s ease-in 0s;

  &.state-0 { background-color: #CCCCCC; color:black;   }
  &.state-1 { background-color: #F2DEDE; color:#A94442; }
  &.state-2 { background-color: #ffd659; color:#725707; }
  &.state-3 { background-color: #DFF0D8; color:#3C763D; }

  .switcher-state {
    position:relative;
    width:25px;
    height:25px;
    margin:0px;
    z-index:2;
 }

  // The switch
  .switch {
      position:absolute;
      z-index:1;
      width: 25px;
      height: 25px;
      margin: -1px -1px;
      background: white;
      top: 0;
      bottom: 0;
      right: 25px;
      border: 1px solid #777777;
      border-radius: 15px;
      transition: all 0.3s ease-in 0s;
  }

  &.state-0 .switch { left:0px; }
  &.state-1 .switch { left:25px; }
  &.state-2 .switch { left:50px; }
  &.state-3 .switch { left:75px; }


  // The icons
  div i {
      transition: all 0.3s ease-in 0s;
      position:absolute;
      top:4px;
      font-size:15px;
      color:#BBB;

      &.icon-0  { left:5px; }
      &.icon-1  { right:6px; }
      &.icon-2  { right:6px; }
      &.icon-3  { right:5px; }
  }

  &.state-0 .icon-0 { color:inherit; }
  &.state-1 .icon-1 { color:inherit;; }
  &.state-2 .icon-2 { color:inherit; }
  &.state-3 .icon-3 { color:inherit; }
}
