/*###########################
####### Developpements ######
###########################*/
div#developpements-container {
	@full-block();
	background-color:white;

	font-family: ComputerModernSerif;
	font-size:18px;
	letter-spacing: -0.7px;

	div.jumbotron {
		margin-bottom:0px;
		background-color:@my-yellow;
		border-bottom:1px dashed #666;
	}

	.table-header {
		background-color:@my-yellow;
	}

	table th {
		padding-right:8px !important;
		div {
			padding-right:15px;
		}
	}

/*

	section.developpement {
		@full-width();
		padding-top:10px;

		h3 {
			@full-width();

			a {
				@full-block();

				background-color:@lecons-content-title-bg;
				border:1px solid @lecons-content-title-border;
				border-radius:@lecons-content-title-border-radius;

				font-size:@lecons-content-title-font-size;
				line-height:@lecons-content-title-line-height;
				color:black;
				text-decoration:none;
				text-indent:10px;
			}
		}

		p {
			font-size:15px;
			padding:3px 7px 5px;
		}
	}
*/
}



/*##################################
### Single developpement display ###
##################################*/

section#developpement {
	@full-width();

	font-family: ComputerModernSerif;
	font-size:18px;
	text-align: justify;
	letter-spacing: -0.7px;

	div.jumbotron {
		margin-bottom:0px;
		background-color:@my-yellow;

		h1 {
			font-size:@font-size-h1;
		}

		button.developpement-add-btn {
			font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
			font-size:14px;
			letter-spacing:0px;
			margin-bottom:-20px;
		}
	}


	div#commentaire {
		font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size:14px;
		letter-spacing:0px;

		textarea {
			resize: none;
			width:100%;
			min-height:40px;
			padding:8px 10px 0px;
			background-color:#fff6cc;
			border:1px solid #ccc;
			border-top:none;
			border-bottom:1px dashed #ccc;

			position:relative;
			z-index:1;

			&:focus {
				outline: 0;
				box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.42);
			}
		}

		button {
			display:block;
			padding:6px 8px;
			position:relative;
			left:0px;
			top:2px;
			z-index:0px;
			margin-bottom:-34px;

			transition:left 0.8s;
		}
	}



	div.strip {
		@full-width();
		min-height:150px;
		padding-bottom:30px;

		h2 {
			font-weight:bold;
			font-size:@font-size-h3;
		}

		&#commentaire {
			min-height:0px;
			padding-bottom:5px;
			overflow:visible;
		}


		div#references {
			font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
			font-size:14px;
			margin:7px 0px 0px;

			select {
				height:20px;
			}

			ul.chosen-choices {
				background-image:url('/assets/images/book.png');
				background-repeat:no-repeat;
				background-size:23px;
				background-position:8px 7px;
				padding-left:40px;

				li.search-choice {
					background-color:#efefef;
				}

				a.search-choice-close {
					top:6px;
				}
			}
		}


		&#couplage {
			overflow: visible;
			min-height:10px;
			padding-bottom:0px;
			background-color:rgb(245, 245, 245);
			border-top:1px solid rgb(221, 221, 221);
			border-bottom:1px solid rgb(221, 221, 221);

			h2 button {
				.btn-link();
				.pull-right();

				&.developpement-lock {
					font-family:'FontAwesome';
					font-size:24px;
					margin-right:9px;

					i::before {
						content: '\f13e';
					}
				}

				&.developpement-delete {
					font-size:18px;
					margin-top:4px;
				}
			}

			ul.couplage-lecon {
				font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
				font-size:14px;
				letter-spacing:0px;

				display:flex;
				flex-direction:column;
				padding-left:0px;
				margin:15px 5px 15px;

				li.couverture-rating {
					transition:height 1s, padding 1s, border 1s;
					padding-left:0px;
					overflow:hidden;
					&.checked {
						background-color:#EAEAEA;
					}

					&:first-of-type {
						border-radius:0px;
					}

					div.couverture-checkbox {
						transition:margin 1s;
						float:left;
						width:39px;
						height:42px;
						border-right:1px solid #ddd;
						margin:-11px 0px -11px 0px;
						text-align:center;
						line-height:45px;
						cursor:pointer;

						&::before {
							content: '\f096';
							font: 1.6em 'FontAwesome';
						}
						&.checked::before {
							content: '\f14a';
						}
					}

					div.rating {
						margin-left:11px;
					}

					div.rating ~ div {
						transition:margin 1s;
						margin-left:145px;
						margin-right:40px;

						font-family:ComputerModernSerif;
						font-size:18px;
						letter-spacing:-0.4px;
						line-height:22px;
						margin-bottom:-2px;
					}

					button {
						transition:margin 1s;
						font-size:16px;
						margin:-1px -10px -2px 0px;

					}


					&.checked.couverture-rating-5 { order:1;  }
					&.checked.couverture-rating-4 { order:2;  }
					&.checked.couverture-rating-3 { order:3;  }
					&.checked.couverture-rating-2 { order:4;  }
					&.checked.couverture-rating-1 { order:5;  }
					&.couverture-rating-5         { order:7;  }
					&.couverture-rating-4         { order:8;  }
					&.couverture-rating-3         { order:9;  }
					&.couverture-rating-2         { order:10;  }
					&.couverture-rating-1         { order:11; }
				}

				li.separator {
					order:6;

					border:none;
					background-color:transparent;
					padding:6px;
				}

				li.rating-add-container {
					order:12;
				}
			}

			&.locked {
				h2 button.developpement-delete {
					display:none;
				}

				h2 button i::before {
					content: '\f023';
				}

				ul li.couverture-rating button {
					margin-right:-40px;
				}

				ul li.rating-add-container {
					height:0px;
					border:0px;
					overflow:hidden;
				}
			}
		}


		&#details {
			background-color:white;
		}

		&#lecons {
			background-color:@my-lyellow;

			h2 button {
				.pull-right;
				.btn;
				.btn-default;

				margin-top:-5px;
				padding:3px 11px;
				font-size:18px;

				span {
					.caret;
					transform:rotate(180deg);
				}
				&.collapsed span {
					transform:rotate(0deg);
				}
			}

			ul {
				padding-left:0px;

				li {
					list-style-type: none;

					a {
						@full-block();
						color:black;

						span.display-rating {
							font-size:14px;
						}
					}
				}
			}
		}


		&#votes {
			overflow: visible;
			min-height:10px;
			padding-bottom:0px;
			background-color:rgb(245, 245, 245);
			border-top:1px solid rgb(221, 221, 221);
			border-bottom:1px solid rgb(221, 221, 221);

			p {
				text-align:center;
				font-weight:bold;
				font-size:@font-size-h4;
				margin-top:10px;

				a, a:hover {
					text-decoration:none;
					span {
						.caret;
						transform:rotate(180deg);
					}
					&.collapsed span {
						transform:rotate(0deg);
					}
				}
			}

			div#edit-votes {
				padding-bottom:20px;

				ul#list-votes {
					margin:0px;
					padding-left:20px;

					& > li.list-group-item {
						background:transparent;

						& > span, & > a {
							font-size:14px;
							margin-top:3px;
						}
					}


					.chosen-container {
					    width:100%!important;
					    font-size:18px;

					    .chosen-search input[type="text"] {
						    width:100% !important;
						}

						.chosen-drop {
	 					   width: 100%!important;
						}

						.chosen-single.chosen-default {
							height:40px;
						}
					}
				}

				input.btn {
					letter-spacing:0px;
					font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
				}
			}
		}

		&#versions {
			background-color:white;

			& > div > h2 {
				margin-bottom:20px;
			}

			h2 a.btn {
				letter-spacing:0px;
				font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
			}
		}
	}
}




/*##############################
########### Kiviat #############
##############################*/
div#kiviat-wrapper {
	@full-block();
	background-color:white;

	overflow:hidden;
	display:flex;
  flex-direction: row;

  @media(max-width: @screen-desktop) {
	  min-height:1000px;
	  overlow:auto;
	  flex-direction: column;
	}


	/*##############################
	## Left menu : lecons chooser */
	div#kiviat-lecons {
	  @media(min-width: @screen-desktop) {
			flex:@kiviat-lecons-flex;
			border-right:1px solid @my-lgrey;
		}
	  @media(max-width: @screen-desktop) {
	  	height:200px;
			border-bottom:1px solid @my-lgrey;
	  }
		background-color:white;

		display:flex;
	  flex-direction: column;
	  overflow:hidden;

		h3 {
			@full-width();
			height:@kiviat-lecons-h3-height;
			background-color:@my-lblue;
			border-bottom:1px solid @my-lgrey;

			line-height:@kiviat-lecons-h3-line-height;
			text-align:center;
			font-size:@kiviat-lecons-h3-font-size;
			color:black;
		}



		/*#### List of lecons ####*/
		div {
			overflow:auto;
			overflow-x:hidden;

			ul {
				@full-block();
				display:flex;
				flex-wrap:wrap;
				justify-content:space-around;

				li {
					width:@kiviat-lecons-li-full-width + 8;
					height:@kiviat-lecons-li-height;
					margin:@kiviat-lecons-li-vmargin 0px;
					padding:0px;

					list-style-type:none;

					div.kiviat-lecon {
						display:block;
						width:@kiviat-lecons-li-full-width;
						height:@kiviat-lecons-li-height;
						margin:auto;

						a {
							display:block;
							width:@kiviat-lecons-li-width;
							height:@kiviat-lecons-li-height;
							padding-left:@kiviat-lecons-li-indent;

							border-radius:@kiviat-lecons-li-border-radius;
							border:1px solid @my-lgrey;
							background-color:@my-lblue;

							line-height:@kiviat-lecons-li-line-height;
							font-size:@kiviat-lecons-li-font-size;
							color:inherit;
							text-decoration:none;

							.add, .remove {
								display:none;
							}

							&:hover {
								width:@kiviat-lecons-li-full-width;
								background-color:lighten(@my-lblue, 5%);
								color:black;
								border-color:black;

								.add { display:inline;}
							}

							&.selected, &.selected:hover {
								width:@kiviat-lecons-li-full-width;
								background-color:@my-lred;

								.add 		{ display:none;		}
								.remove { display:inline;	}
							}
						}
					}
				}
			}
		}
	}


	/*################################
	## Canvas to draw data			*/
	div#kiviat-canvas	{
		flex:@kiviat-canvas-flex;
		@media(max-width: @screen-desktop) {
			min-height:200px;
		}

		display:flex;
		flex-direction: column;
		@media(max-width: @screen-desktop) {
			flex-direction: row;
		}


		div#kiviat-buttons {
			@media(min-width: @screen-desktop) {
				height:@kiviat-lecons-h3-height;
			}
			@media(max-width: @screen-desktop) {
				width:39px;
			}

			#kiviat-help {
	 			height:@kiviat-lecons-h3-height;
	 			width:39px;
	 			border-radius:0px;
	 			border-top:0px;
	 			border-left:0px;
	 		}

	 		#kiviat-mode {
	 			height:@kiviat-lecons-h3-height;
	 			width:39px;
	 			border-radius:0px;
				@media(min-width: @screen-desktop) {
		 			border-top:0px;
		 			border-right:0px;
				}
				@media(max-width: @screen-desktop) {
		 			border-top:0px;
		 			border-left:0px;
				}

	 			background-position: center center;
	 			background-repeat: no-repeat;

	 			&.unite {
	 			 	background-image:url('../images/unite.png');
	 			}

	 			&.intersect {
	 			 	background-image:url('../images/intersect.png');
	 			}
	 		}
		}

		div#kiviat-chart-container {
			flex:1;

			canvas {
				width:100%;
				height:100%;
			}
		}
	}



	/*################################
	## List of devs matching devs   */
	div#kiviat-developpements {
		@media(min-width: @screen-desktop) {
			flex:@kiviat-developpements-flex;
			border-left:1px solid @my-lgrey;
		}
		@media(max-width: @screen-desktop) {
			height:300px;
			border-top:1px solid @my-lgrey;
		}


		h3 {
			@full-width();
			height:@kiviat-lecons-h3-height;

			border-bottom:1px solid @my-lgrey;
			background-color:@my-lblue;
			line-height:@kiviat-lecons-h3-line-height;
			text-align:center;
			font-size:@kiviat-lecons-h3-font-size;
			font-weight:normal;
			color:black;
		}


		/*## List ##*/
		ul {
			width:calc(~"100% + " @kiviat-developpements-scroller-size);
			height:calc(~"100% - " @kiviat-lecons-h3-height);
			padding:0px;
			margin:0px;
			margin-left:-@kiviat-developpements-scroller-size;
			overflow:auto;
			overflow-x:hidden;

			&:first-of-type {
				display:none;
			}

			li {
				display:block;
				width:calc(~"100% - " @kiviat-developpements-scroller-size);
				height:@kiviat-developpements-li-height;
				padding:0px;
				margin:0px;
				margin-left:@kiviat-developpements-scroller-size;

				border-bottom:1px solid lighten(@my-lgrey,5%);
				list-style-type:none;
				text-indent:@kiviat-developpements-li-indent;
				line-height:@kiviat-developpements-li-line-height;
				cursor:default;
				color:black;
				font-family: ComputerModernSerif;
				font-size: @kiviat-developpements-li-font-size;
				clear:both;

				&:hover 	 {	background-color:@my-lblue; }
				&.selected {	background-color:@my-lred;  }


				a {
					display:inline-block;
					width:@kiviat-developpements-li-height;
					height:@kiviat-developpements-li-height;
					float:right;
					color:black;
					text-align:center;
					text-indent:0px;
				}


				/* Scroller */
				div {
					display:none;

					width:0;
					height:0;
					border-style:solid;
					border-width:@kiviat-developpements-scroller-size @kiviat-developpements-scroller-width @kiviat-developpements-scroller-size 0;
					border-color:transparent lighten(@my-lgrey,5%) transparent transparent;
					margin-left:-@kiviat-developpements-scroller-size;
					margin-top:(@kiviat-developpements-li-height - 2*@kiviat-developpements-scroller-size - 1)/2;
					float:left;

					div {
						border-width: @kiviat-developpements-scroller-size @kiviat-developpements-scroller-width @kiviat-developpements-scroller-size 0;
						border-color: transparent white transparent transparent;
						margin-top:-@kiviat-developpements-scroller-size;
						margin-left:1px;
					}
				}

				&:hover div, &.selected div {
					display:block;
				}
				&:hover 	 div div {  border-right-color:@my-lblue;	}
				&.selected div div {	border-right-color:@my-lred; }
			}
		}
	}
}

.tour button.close {
	margin-right:6px;
	margin-top:3px;
}
