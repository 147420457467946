@full-block: { display:block; height:100%; width:100%; padding:0px; margin:0px; };
@full-width: { width:100%; padding:0px; margin:0px; };

html, body {
    width:100%;
    height:100%;
    min-height:100%;
}


body {
    padding-top:@nav-total-height;
    background-color:white;

    @media(max-width: @grid-float-breakpoint) {
        padding-top:@nav-total-height+3;
    }
}


.fa-stack {
    .fa-stack-action {
        font-size: 120%;
        margin: -30% 0px 0px 50%;
    }
}


div#couteau-suisse {
  float:left;
  width:20px;
  height:28px;
  position:relative;
  transform:rotate(90deg);
  margin-top:7px;

  background-image:url('/assets/images/couteau_suisse.png');
  background-size:contain;
  background-repeat:no-repeat;
  background-position:center center;

  div {
    position:absolute;
    width:100%;
    height:100%;
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center center;

    &#outil1 {
      background-image:url('/assets/images/couteau_suisse_outil_1.png');
      transform-origin: 50% 17.61%;
    }
    &#outil2 {
      background-image:url('/assets/images/couteau_suisse_outil_2.png');
      transform-origin: 50% 50%;
    }
    &#outil3 {
      background-image:url('/assets/images/couteau_suisse_outil_3.png');
      transform-origin: 50% 80%;
    }
  }
}


div#tirages {
    p {
        font-size:17px;
        text-align: justify;
    }

    ul {
        padding: 10px 20px;

        li {
            a {
                font-size:17px;
                padding:0px;
                padding-left:43px;
                text-indent:-43px;
            }

            div {
                text-align:center;
            }
        }
    }
}


/*
    Index page
 */
div#index-page {
    .jumbotron {
        margin-bottom:0px;
        padding-top:7px;

        .container {
            padding-top:0px;

            div.alert {
                margin-bottom:40px;
            }
        }
    }

    .strip .container {
        padding-top:15px;
        padding-bottom:30px;
        font-size:20px;
        line-height:34px;
        text-align:justify;

        blockquote {
            font-family: Impact, "Arial Black", sans-serif;
            font-size:19px;
            margin-top:10px;
            margin-top:15px;
        }
    }
}



/*
    The navbar
*/
#navbar {
    &:extend(.navbar all, .navbar-default all, .navbar-fixed-top all);
    min-height:@navbar-height;
    margin-bottom:0px;

    #header {
        &:extend(.navbar-header all);
        @media(max-width: @grid-float-breakpoint) {
            margin-right:-15px;
        }

        a {
            &:extend(.navbar-brand);
            line-height: 26px;

            color:white;
            span:nth-child(1) {
                color:@my-dblue;
            }

            &:hover {
                text-decoration: none;
            }
        }

        button {
            &:extend(.navbar-toggle all);
            background-color:transparent;
            margin:0.5px 0px;
            padding:10px;
            border:none;

            span.icon-bar {
                background-color:#EEE;
            }

            &.collapsed {
                background-color:transparent;
                span.icon-bar {
                    background-color:#888;
                }

                &:hover span.icon-bar{
                    background-color:#EEE;
                }
            }
        }
    }


    #navbar-collapsable {
        &:extend(.navbar-collapse all);
        padding:0px;

        ul {
            &:extend(.nav all, .navbar-nav all);

            &:nth-of-type(2) {
                 &:extend(.navbar-right all);
            }

            li {
                height:@navbar-height;
                padding-top:@navbar-li-padding;

                @media(max-width: @grid-float-breakpoint) {
                    padding-top:0px;
                }


                a {
                    height:@navbar-base-height + 1;

                    border-top-left-radius:@navbar-li-border-radius;
                    border-top-right-radius:@navbar-li-border-radius;
                    border:1px solid transparent;
                    margin-bottom:-1px;

                    font-size:@font-size-large;
                    line-height: @font-size-large - 2;
                    color:@navbar-default-link-color;

                    transition-property:background-color,color;
                    transition-duration:300ms;

                    @media(max-width: @grid-float-breakpoint) {
                        height:@navbar-height;
                        border-radius:0px;
                        border:none;
                        margin-bottom:0px;
                    }
                }
                &:hover a, a:focus {
                    color:@navbar-default-link-active-color;
                    background-color:@navbar-default-link-hover-bg;
                }


                &.active a {
                    color:@navbar-default-link-hover-color;
                    background-color:@navbar-default-link-active-bg;
                    border-bottom-color:@my-green;
                }
            }
        }
    }
}



/*
    The subnavbar
*/
#subnavbar {
    &:extend(.navbar all, .navbar-default all, .navbar-fixed-top all);

    margin-bottom:0px;
    background-color:@subnavbar-bg;
    border-color:@subnavbar-border;
    top:@navbar-height+1;
    z-index:1000;
    min-height:@subnavbar-height + @subnavbar-li-vmargin;



    #subnavbar-header {
        &:extend(#navbar #header all);

        & > span {
            &:extend(.navbar-brand);
            line-height: 22px;
            color:#333;

            display:none;
            @media(max-width: @grid-float-breakpoint) {
                display:inline;
            }
        }

        button {
            span.icon-bar, &:hover span.icon-bar{ background-color:#DDD; }
            &.collapsed span.icon-bar { background-color:#444; }
        }
    }

    #subnavbar-collapsable {
        &:extend(.navbar-collapse all);
        border-color:transparent;
        @media(min-width: @grid-float-breakpoint) {
            padding:0px;
        }

        .subnavbar-right {
             &:extend(.navbar-right all);
        }

        & > ul {
            &:extend(.nav all, .navbar-nav all);

            @media(max-width: @grid-float-breakpoint) {
                margin:0px -15px;
                border-bottom:1px solid @my-lgrey;
            }

            & > li {
                min-height:@subnavbar-li-height;
                margin: @subnavbar-li-vmargin @subnavbar-li-hmargin @subnavbar-li-vmargin;
                border-radius:@subnavbar-li-border-radius;
                border:1px solid transparent;
                color:black;

                @media(max-width: @grid-float-breakpoint) {
                    min-height:@subnavbar-height;
                    margin:0px;
                    border-radius:0px;
                    border:none;
                    border-top:1px solid @my-lgrey;
                }


                &.active {
                    background-color:@my-lgreen;
                    border-color:@my-lgrey;
                }

                &.clickable {
                    cursor:pointer;

                    &:hover {
                        background-color:@my-mgreen;
                        border-color:@my-lgrey;
                    }
                }


                &.separator {
                    padding:0px;
                    margin:(@subnavbar-li-vmargin - 1) (@subnavbar-li-hmargin - 2) (@subnavbar-li-vmargin + 1);
                    border-color:transparent;
                    background-color:transparent;
                    color:@my-dgrey;

                    &::before {
                        content:@subnavbar-li-separator;
                    }

                   @media(max-width: @grid-float-breakpoint) {
                        margin:0px;
                        border-top:1px solid @my-lgrey;
                        text-align:center;
                        font-size:24px;
                        line-height:14px;
                        &:before {
                            content:'⌄';
                        }
                    }
                }

                &.dropdown {
                    &:extend(#subnavbar #subnavbar-collapsable ul li.clickable all);

                    ul {
                        &:extend(.dropdown-menu all);
                        background-color:@my-lgreen;
                        border:1px solid @my-lgrey;

                        li:hover a {
                            background-color:@my-mgreen;
                        }
                    }

                    &.open {
                        background-color:@my-mgreen;

                        & > a {
                            background-color:transparent;
                        }

                        ul {
                            display:block;
                        }
                    }
                }

                & > a, & > a:hover, & > a:focus, & > span {
                    min-height:@subnavbar-li-height;
                    padding:0px @subnavbar-li-padding;
                    line-height:@subnavbar-li-line-height;
                    background-color:transparent;
                    color:inherit;
                    border:none;

                    @media(max-width: @grid-float-breakpoint) {
                        line-height:@subnavbar-li-line-height + @subnavbar-li-vmargin;
                    }
                }
            }
        }


        & > form {
            &:extend(.navbar-form all);

            .input-group {
                margin:(@subnavbar-li-vmargin - 1) @subnavbar-li-hmargin;

                input.form-control {
                    height:@subnavbar-li-height + 2;
                    padding:5px 10px;
                    background-color:@my-lgreen;
                    border-color:@my-lgrey;
                    color:black;
                    font-size:12px;
                    line-height:1.5;
                }

                .input-group-btn {
                    button.btn {
                        height:@subnavbar-li-height + 2;
                        margin:0px 0px 0px -1px;
                        padding:0px 7px;
                        font-size:12px;
                        border-color:@my-lgrey;
                        background-color:@my-lgreen;

                        &:hover {
                            background-color:@my-mgreen;
                        }
                    }
                }
            }
        }
    }
}



/*
    The message
*/
div#message {
    margin:-42px 0px 0px;
    transition-property:margin;
    transition-duration:1000ms;
    border-radius:0px;
    border-bottom:1px solid lighten(@subnavbar-border, 10%);
}


/*
    Content
*/
div#content {
    min-height:100%;

    &.white {
        background-color:white;
    }
}


div.strip {
    @full-width();
    overflow:hidden;
    background-color:white;

    &.lblue  {  background-color:@my-lblue;                 }
    &.llblue {  background-color:lighten(@my-lblue, 20%);   }
}


div.jumbotron {
    font-family: ComputerModernSerif;
    font-size:18px;
    text-align: justify;
    letter-spacing: -0.7px;

    &.yellow {
        background-color:@my-yellow;
    }

    h1 {
        @media(min-width: @grid-float-breakpoint) {
            font-size:52px;
        }
    }

    a, button {
        letter-spacing: 0px;
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    }
}


/*
    Divers
*/
form .col-md-0 {
    display:none;
}


label.control-label-lg {
    @media (min-width: @screen-sm-min) {
        padding-top: (@padding-large-vertical + 1);
        font-size: @font-size-large;
    }
}


.modal {
    letter-spacing: 0px !important;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
}


.form-group-lg div.checkbox {
    &:extend(.input-lg all);
    padding-left:0px;
}


/*
    Version display
*/
@display-offset: 2;
@display-offset2: 3;
.version {
    &:extend(.panel all, .panel-default all);

    & > div:nth-of-type(1) {
        &:extend(.panel-heading all);
        color:#333;
        background-color:#F5F5F5;
        border-color:#DDD;

        & > a, & > button {
            .btn();
            .btn-warning();
            .pull-right();

            letter-spacing: 0px;
            font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
            margin-top:-11px;
            margin-right:-16px;
            line-height:26px;
            border-top-left-radius:0px;
            border-bottom-left-radius:0px;
            border-bottom-right-radius:0px;
        }
        &.collapsed > a, &.collapsed > button { border-bottom-right-radius:3px; }

        & > button {
            .btn-danger();

            & + a {
                margin-right:0px;
                border-radius:0px;
            }
        }


        h3 {
            &:extend(.panel-title all);
        }


        span.caret              { transform:rotate(180deg);   }
        &.collapsed span.caret  { transform:rotate(0deg);     }
    }

    & > div:nth-of-type(2) {
        &:extend(.panel-collapse all, .panel-body all);
        padding:0px;
        clear:both;

        & > ul {
            &:extend(.list-group all);
            padding:0px;
            margin-bottom:0px;

            & > li {
                .list-group-item();
                .clearfix();

                border-width:1px 0px 0px 0px;
                &:first-of-type {
                    border-width:0px;
                }


                & > strong {
                    .make-md-column(2);
                }

                & > div, & > ul {
                    .make-md-column(10);

                    li {
                        list-style-type: none;
                    }

                    a:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.display.panel-footer {
    strong {
        line-height:32px;
    }
}





/*
    Datatables
*/
#developpements-table_wrapper,
#references-table_wrapper,
#lecons-table_wrapper,
#couplages-table_wrapper {
    div.table-header {
       padding-top:5px;

        div.container div {
            .make-md-column(6);
            padding:0px;

            span.glyphicon {
                top:4px;
            }
        }

        label {
            margin-top:3px;

            select {
                margin-top:-3px;
            }
        }

        input, select {
            font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        }
    }
}

table#developpements-table,
table#references-table,
table#lecons-table,
table#couplages-table {
    margin-top:0px !important;

    th:last-of-type {
        &::after { display:none; }
        div::after {
            float:right;
            display: block;
            font-family: "Glyphicons Halflings";
            opacity: 0.5;
        }

        &.sorting_asc div::after {
            content: "\e155";
        }
        &.sorting_desc div::after {
            content: "\e156";
        }
        &.sorting div::after {
            opacity: 0.2;
            content: "\e150";
        }
    }

    tr.clickable {
        cursor:pointer;
    }

    td div, th div {
        .container();
        padding-right:0px;
    }

    td.primary {
        .text-primary();
        font-weight:bold;
    }
}

#developpements-table_paginate,
#references-table_paginate,
#lecons-table_paginate,
#couplages-table_paginate {
    text-align:center;
}
