@import "bootstrap/variables.less";
@import (reference) "bootstrap/glyphicons.less";

@star-width:  18px;
@rating-height: @star-width - 1px;

@star-color: black;
@ostar-color: lighten(#000, 70%);

.rating {
    display:block;
    height:@rating-height;
    margin:auto;
    margin-right:3px;

    position:relative;
    float:left;
    overflow:hidden;
    font-size:@rating-height - 2px;


    .generate-scales(6);
    .generate-scales(@n, @i: 1) when (@i =< @n) {
        &.rating-scale-@{i} {
            width: @star-width * @i;
        }
        .generate-scales(@n, (@i + 1));
    }
    &.rating-scale-1 {
        display:none;
    }


    .rating-input {
        display:none;
    }

    .rating-star {
        float:right;
        width:@rating-height;
        height:@rating-height;
        padding:0;
        margin-right:1px;
        cursor:pointer;

        span {
            .glyphicon;
            .glyphicon-star-empty;
            color:@ostar-color;
        }
    }

    .rating-input:checked ~ .rating-star span,
    .rating-input[checked="checked"] ~ .rating-star span {
        .glyphicon-star;
        color:@star-color;
    }

    &:hover {
        .rating-input ~ .rating-star span {
            .glyphicon-star-empty;
            color:@ostar-color;
        }

        .rating-star:hover span,
        .rating-star:hover ~ .rating-star span {
            .glyphicon-star;
            color:@star-color;
        }
    }

    &.disabled {
        cursor:default;
        .rating-star {
            cursor:default;
        }
    }

    &.disabled:hover {
        .rating-input ~ .rating-star span {
            .glyphicon-star-empty;
            color:@ostar-color;
            cursor:default;
        }

        .rating-input:checked ~ .rating-star span,
        .rating-input[checked="checked"] ~ .rating-star span {
            .glyphicon-star;
            color:@star-color;
        }
    }

    /* Empty set symbol for 0 star vote */
    .rating-zero {
        float:right;
        width:@rating-height;
        height:@rating-height;
        padding:0;
        margin-right:1px;
        cursor:pointer;

        span {
            font-size:16px;
            line-height:18px;
            color:@star-color;
        }
    }

    .rating-input:checked ~ .rating-star ~ .rating-zero span {
        color:@ostar-color;
    }

    &:hover {
        .rating-input ~ .rating-zero span {
            color:@ostar-color;
        }

        .rating-zero:hover span {
            color:@star-color !important;
        }
    }
}



/* Styling for displaying rating with stars */
.display-rating {
    .glyphicon-star {
        color:@star-color;
    }

    .glyphicon-star-empty {
        color: @ostar-color;
    }
}

.form-group-lg li.rating-item {
    font-size: 18px;
    line-height: 1.33333;
    padding: 12px 16px;

    .rating {
        margin-top:4px;
    }
}


/* Styling for list of rating */
ul {
    li.list-group-item {
        button {
            .btn-link();
            float:right;
            margin-right:-8px;
            border:0px;
        }

        .generate-scales-bis(6);
        .generate-scales-bis(@n, @i: 1) when (@i =< @n) {
            .rating-scale-@{i} ~ div {
                margin-left: @star-width * @i + 5;
                margin-right:15px;
            }
            .generate-scales-bis(@n, (@i + 1));
        }
    }

    li.rating-add-container {
        padding:0px;

        div.rating-add {
            border:none;
            border-top-left-radius:0px;
        }

        div.chosen-container a.chosen-single {
            border:none;
            border-top-right-radius:0px;
            border-left:1px solid #CCC;
        }
    }
}

ul li:first-of-type {
    div.rating-add {
        border-top-left-radius:4px;
    }
}
